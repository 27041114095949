import React from "react"
import { Link } from "gatsby"
import { Card } from "react-bootstrap"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./style.scss"

export default ({ title, name, relationships }) => {
  const slug = name
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-")
    .toLowerCase()

  const imageThumb =
    relationships?.field_image?.relationships?.field_media_image?.thumbnail

  return (
    <Link to={slug ? `/first-responders/staff/${slug}` : ""}>
      <Card className="staff-cards">
        <Img
          key={"img-thumb-" + imageThumb.childImageSharp.fixed.originalName}
          loading={"lazy"}
          fixed={imageThumb.childImageSharp.fixed}
          alt={name}
        />
        <Card.Body>
          <Card.Title className={"pt-3"}>{name}</Card.Title>
          <Card.Text className={"position pb-2"}>{title}</Card.Text>
          <Card.Text className={"pb-3"}>
            <FontAwesomeIcon className="mr-2" icon={"arrow-right"} />
            Read Bio
          </Card.Text>
        </Card.Body>
      </Card>
    </Link>
  )
}
