import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Button from "../components/Button"
import StaffCards from "../components/Cards/StaffList"
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"
import "./../styles/dark-mode.scss"

export default ({
  pageContext: {
    item: { title, name, description, relationships },
    staff,
    pageInfo: { cta, header },
  },
}) => {
  const imageDetail =
    relationships?.field_image?.relationships?.field_media_image?.imageDetail
  console.log(imageDetail)
  console.log(imageDetail.childImageSharp.fixed)

  const thumbnail =
    relationships?.field_image?.relationships?.field_media_image?.thumbnail

  console.log(thumbnail.childImageSharp.fixed)

  return (
    <Layout pageInfo={{ pageName: "About Staff Bio" }} fluid={true}>
      <SEO
        title="About Staff Detail"
        keywords={[`gatsby`, `react`, `bootstrap`]}
      />
      <Container className={"mt-sm-0 featured-area"} fluid={true}>
        <Container className={"section-padding staff-bio"}>
          <Row className={"pb-4"}>
            <Col>
              <Button
                to={`/first-responders/about`}
                icon={`arrow-left`}
                className={`back btn btn-red-outline`}
                variant={`red-outline`}
                label={cta}
              />
            </Col>
          </Row>
          <Row>
            <Col
              md={10}
              lg={5}
              className={"pt-md-4 order-2 order-lg-1 offset-lg-0 offset-md-1"}
            >
              <h1 className={"pb-3"}>{name}</h1>
              <h3>{title}</h3>
              <p>{description}</p>
            </Col>
            <Col md={12} lg={7} className={"order-lg-2 order-1"}>
              <Img
                className={"rounded"}
                key={`img-featured-${imageDetail.childImageSharp.fixed.originalName}`}
                loading={"lazy"}
                fixed={imageDetail.childImageSharp.fixed}
                alt={name}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className={"explore story-cards advisory-group"} fluid={true}>
        <Container>
          <Row className={"pl-3 pb-3"}>
            <h2>{header}</h2>
          </Row>
          <Row>
            <StaffCards items={staff} />
          </Row>
        </Container>
      </Container>
    </Layout>
  )
}
