import React from "react"
import Card from "./Staff"
import "./style.scss"
import { Container, Row, Col } from "react-bootstrap"

export default ({ items }) => (
  <Container className={"px-3"}>
    <Row>
      {items &&
        items.map((item, i) => (
          <Col
            md={6}
            lg={4}
            key={i}
            className={"pb-4 d-flex"}
            data-sal="slide-up"
            data-sal-duration="800"
            data-sal-delay="1200"
            data-sal-easing="ease"
          >
            <Card key={i} {...item} />
          </Col>
        ))}
    </Row>
  </Container>
)
